import React from "react";
import { Container } from "@material-ui/core";
import { graphql } from "gatsby";
// import { useTranslation } from "gatsby-plugin-react-i18next";
import SEO from "../Components/SEO/index";
import Layout from "../Components/Navigation";
import * as PrivacyHtml from "../Components/Policy/index";

function PrivacyPage() {
  // const { i18n } = useTranslation();
  // const currentLang = i18n.language;
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Container
        style={{ maxWidth: 800, marginTop: "4em", marginBottom: "4em" }}
      >
        <div
          style={{
            maxWidth: 960,
            margin: "0 auto",
            width: "100%",
          }}
          dangerouslySetInnerHTML={{
            __html: PrivacyHtml["EN"].html,
            // PrivacyHtml[`${currentLang.toUpperCase()}`].html,
          }}
        />
      </Container>
    </Layout>
  );
}
export default PrivacyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
