export const EN = {
  html: `
 <p class="p1"><b>Privacy Policy</b></p>
 <p class="p2">
 <b>Kunana Company Limited</b> (the “<b>Company</b>”) would like to
 inform you who contacts the Company that it is necessary to collect,
 compile and use your personal data for the Company’s service in
 accordance with this Privacy Policy. By contacting and disclosing your
 personal data to the Company for the purposes of communication,
 coordination, and/or provision of the Company's services to you, it
 deems that you agree and accept to be bound by and comply with this
 Privacy Policy.
</p>
<p class="p2">
 <span class="s1">Personal data being processed</span>, the Company
 may obtain your personal data from various channels as follows:
</p>
<p class="p3">
 (1) <span class="Apple-tab-span"> </span>Directly from you through
 the Company's communications with you;
</p>
<p class="p3">
 (2)<span class="Apple-tab-span"> </span>Indirectly, via a referral
 from a third party to whom you may grant consent to disclose your
 personal data to the Company; or
 <span class="Apple-converted-space"> </span>
</p>
<p class="p3">
 (3)<span class="Apple-tab-span"> </span>Automatically collected
 via system when you access the Company's website and/or use the
 services.
</p>
<p class="p2">
 The personal data that the Company is necessary to collect, compile,
 use and process under this Privacy Policy are as follows:
 <span class="Apple-converted-space"> </span>
</p>
<p class="p3">
 (1) <span class="Apple-tab-span"> </span>Your full name, including
 your related information or your representative information (in the
 case that you are contacting us in the name of a juristic person)
 which may include your identity information document.
 <span class="Apple-converted-space"> </span>
</p>
<p class="p3">
 (2) <span class="Apple-tab-span"> </span>Contact information such
 as telephone number, email or social media account information.
 <span class="Apple-converted-space"> </span>
</p>
<p class="p3">
 (3) <span class="Apple-tab-span"> </span>Other personal data that
 you may provide to the Company during the communications such as
 information on matters of inquiry, interest information or any other
 personally identifiable information you provide to the Company
 directly through various communication channels.
</p>
<p class="p3">
 (4)<span class="Apple-tab-span"> </span>In case of communication
 via the website, may include your technical information such as IP
 Address, Cookies, including information about your browsing behavior.
</p>
<p class="p2">
 <span class="s1">
   Purpose of the Personal Data Processing and Using
 </span>
 , the Company is necessary to collect, compile and use the Job
 Applicant’s personal data for the following purposes:{" "}
 <span class="Apple-converted-space"> </span>
</p>
<p class="p3">
 (1) <span class="Apple-tab-span"> </span>For the management of
 communications with which you have communicated with the Company, such
 as answering questions, providing relevant information as requested
 and required, managing complaints, or responding to comments you make
 directly to the Company via various communication channels, including
 continuous coordination, service agreement execution, and/or
 performance of rights and obligations that the Company and you may
 agree on; <span class="Apple-converted-space"> </span>
</p>
<p class="p3">
 (2)<span class="Apple-tab-span"> </span>For the performance of
 legal obligations to which the Company may be subject to certain
 conditions. This may include, but is not limited to, performing duties
 associated with the preparation of accounting documents and taxes in
 connection with any services provided by the Company to you;
</p>
<p class="p3">
 (3)<span class="Apple-tab-span"> </span>For the purpose of
 building and improving business relationships, including enhancing the
 service that the Company provides for your benefit, which the Company
 may collect and/or use your personal data for the purpose of control,
 assurance of service, management analysis and resolution of
 business-related issues, including, but not limited to, employee
 training and future service improvement planning;
</p>
<p class="p3">
 (4)<span class="Apple-tab-span"> </span>For the purpose of
 protecting and defending the Company's legal rights in the event that
 you and the Company have a dispute; and
</p>
<p class="p4">
 (5)<span class="Apple-tab-span"> </span>Subject to an explicit
 consent granted to the Company such as marketing and newsletter
 communications, the Company may process the Personal Data for the
 purposes as defined in the consent.{" "}
 <span class="Apple-converted-space"> </span>
</p>
<p class="p2">
 <span class="s1">Retention Period of Personal Data</span>, in
 order to perform in accordance with the purposes described above, the
 Company is necessary to collect, compile and process your personal
 data according to the following period:
 <span class="Apple-converted-space"> </span>
</p>
<p class="p4">
 (1)<span class="Apple-tab-span"> </span>For personal data
 processing for the purpose of providing services, an agreement
 execution and/or the performance of rights and duties under the
 agreement, the Company is necessary to process your personal data.as
 long as the company has a duty to provide services to you;
</p>
<p class="p4">
 (2)<span class="Apple-tab-span"> </span>For personal data
 processing for the purpose of performing the duties according to
 applicable laws, the Company is necessary to process your personal
 data for a period of time specified by applicable laws;
</p>
<p class="p4">
 (3)<span class="Apple-tab-span"> </span>For personal data
 processing for the purpose of building and improving business
 relationships and/or improving the service, the Company reserves the
 right to retain that personal data to the extent that the Company may
 have business necessity. The Company warrants that the retention of
 the personal data will not unreasonably affect the rights of the data
 subject;
</p>
<p class="p4">
 (4)<span class="Apple-tab-span"> </span>For personal data
 processing for the sake of protection and fighting for legitimate
 rights of the Company, the Company has a necessity to keep such
 personal data according to the prescription defined by applicable
 laws; and
</p>
<p class="p4">
 (5)<span class="Apple-tab-span"> </span>In case you give consent
 to the Company to process your personal data for specific purposes,
 the Company will process your personal data until you withdraw such
 consent.
</p>
<p class="p2">
 <span class="s1">Disclosure of the personal data</span>,
 generally, your personal data will not be disclosed, except for the
 circumstance that the Company would need to disclose to the following
 person:
 <span class="Apple-converted-space"> </span>
</p>
<p class="p4">
 (1)<span class="Apple-tab-span"> </span>External service providers
 of the Company engaged for providing supporting services to the
 Company in providing services to you, including consultants of the
 Company. Such disclosure of the personal data
 <span class="Apple-converted-space">  </span>to these third
 parties shall be done in accordance with the purposes and only on a
 need-to-know basis; or
</p>
<p class="p4">
 (2) <span class="Apple-tab-span"> </span>Government, regulatory
 authorities or courts that the Company may be subject to an order, law
 or judgment to disclose the personal data.
</p>
<p class="p2">
 <span class="s1">
   The Company undertakes to implement appropriate personal data
   security measures
 </span>{" "}
 to prevent any unauthorized and unlawful access, modification,
 amendment, or disclosure. The Company commits to reviewing these
 measures on a regular basis to ensure its compatibility to the
 standards and to applicable laws.
 <span class="Apple-converted-space"> </span>
</p>
<p class="p2">
 <span class="s1">Data subject rights</span>, the Company respects
 your rights as the data subject under applicable laws. You can contact
 to the Company to exercise the rights which are (1) the rights to
 withdraw consent, (2) the right to access, (3) the right request a
 copy of the personal data, (4) the right to correct the personal data,
 (5) the right to object to any processing of the personal data, (6)
 the right to request for data portability in the event that the
 Company stores the personal data in the format which is readable or
 commonly used by automated tools or equipment including the right to
 transfer transfer such personal data to other data controllers, (7)
 the right to request for the deletion or de-identification of the
 personal data upon there is no necessity to process, (8) the right to
 request for suspension of the use of the personal data, and (9) the
 right to file a complaint.
</p>
<p class="p2">
 <span class="s1">Company contact information</span>
</p>
<p class="p5">Data Protection Officer</p>
<p class="p5">
 Address: Kunana Company Limited. 740 Soi Phahonyothin 30, Chankasem,
 Chatuchak, Bangkok,Thailand
</p>
<p class="p5">Email: info@kunana.ltd</p>
 `,
};
